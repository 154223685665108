<template>
    <button class="button">
        <div class="button-text">            
            <slot></slot>
        </div>
        <span class="button-icon">
            <i class="bi bi-arrow-right-short"></i>
        </span>
    </button>
</template>

<script>
export default {}
</script>

<style scoped>
.button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.5em;
    width: 150px;
    height: 55px;
    background-color: var(--brand-primary-blue);
    border-radius: 4px;
    color: #fff;
    font-size: 16px;
    font-weight: var(--text-medium);
    text-decoration: none;
    cursor: pointer;
    border: none;
    font-family: inherit;
    box-shadow: 0 9px 35px 0px rgba(0, 188, 197, 0.25);
    transition: transform 0.15s ease,
                box-shadow 0.3s ease;
}
.button:hover {
    transform: translateY(-5px);
    box-shadow: 0 9px 35px 0 rgba(0, 188, 197, 0.4);
}
.button .button-icon {
    margin-left: 8px;
    font-size: 24px;
}
</style>