<template>
    <div>
        <div @click="expandQuestionOne">
            <FAQ
                id="question_one"
                title="Can I use my own domain with illapa?"
                information="Yes, you can use your own domain with illapa."
                secondaryInformation="If you have an existing domain name, you can choose a subdomain, and this will become the URL your customers use to access your cloud."
                terniaryInformation="You will need a SSL certificate for the URL so that you can offer your customers a secure connection."
                ref="question_one">
            </FAQ>
        </div>
        <div @click="expandQuestionTwo">
            <FAQ
                id="question_two"
                title="Can I add my own infrastructure to illapa?"
                information="We are an application service provider control plane, we do not offer our own infrastructure therefore we are always looking to interface with a third party infrastructure."
                secondaryInformation="We support both private virtualised infrastructures, as well as the most popular public clouds and any combination of each."
                terniaryInformation="For private virtualised infrastructures we will need to establish a VPN to enable us to make API calls to the local hypervisor, we do not need a VPN for public clouds."
                ref="question_two">
            </FAQ>
        </div>
        <div @click="expandQuestionThree">
            <FAQ
                id="question_three"
                title="How much does illapa cost?"
                information="We are a subscription model with rolling monthly contracts."
                secondaryInformation="We charge based on the number of instances managed through our application, and as you would expect pricing adjusts based on volume and commitment."
                terniaryInformation=""
                ref="question_three">
            </FAQ>
        </div>
        <div @click="expandQuestionFour">
            <FAQ
                id="question_four"
                title="How do I make sure there is no unauthorised expenditure?"
                information="We are a highly customisable framework; therefore each client can determine the level of user privileges and create bespoke policies to ensure that compliance is maintained at all times."
                secondaryInformation="Through granular hierarchy we can use these policies to create robust approval processes to ensure that there is not any authorised expenditure."
                terniaryInformation=""
                ref="question_four">
            </FAQ>
        </div>
    </div>
</template>

<script>
import FAQ from "./FAQ.vue";

export default {
    components: {
        FAQ
    },
    data: () => ({ }),
    methods: {
        // * Individual Feature Click Events
        expandQuestionOne() {
            this.$refs.question_two.collapseComponent();
            this.$refs.question_three.collapseComponent();
            this.$refs.question_four.collapseComponent();
        },
        expandQuestionTwo() {
            this.$refs.question_one.collapseComponent();
            this.$refs.question_three.collapseComponent();
            this.$refs.question_four.collapseComponent();
        },
        expandQuestionThree() {
            this.$refs.question_one.collapseComponent();
            this.$refs.question_two.collapseComponent();
            this.$refs.question_four.collapseComponent();
        },
        expandQuestionFour() {
            this.$refs.question_one.collapseComponent();
            this.$refs.question_two.collapseComponent();
            this.$refs.question_three.collapseComponent();
        }
    }
}
</script>

<style scoped>

</style>