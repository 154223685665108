<template>
    <div>
        <Hero></Hero>
        <MainContent></MainContent>
    </div>
</template>

<script>
import Hero from "../components/home/Hero.vue";
import MainContent from "../components/home/MainContent.vue";

export default {
    components: { Hero, MainContent },
    metaInfo() {
        return {
            title: 'Multi-Cloud Self-Service Platform | Illapa Cloud',
            meta: [
                {
                    name: 'description',
                    content: 'Illapa is a highly customisable API framework that has been designed from the ground up to help channel partners both MSPs and VARs to be able to capitalise on the investments made by the public cloud vendors like Amazon Web Services, Microsoft Azure, and Google Cloud Platform.'
                },
                {
                    property: 'og:title',
                    content: 'Multi-Cloud Self-Service Platform | Illapa Cloud'
                },
                {
                    property: 'og:description',
                    content: 'Illapa is a highly customisable API framework that has been designed from the ground up to help channel partners both MSPs and VARs to be able to capitalise on the investments made by the public cloud vendors like Amazon Web Services, Microsoft Azure, and Google Cloud Platform.'
                },
                {
                    property: 'og:url',
                    content: 'https://illapa.cloud'
                }
            ],
            link: [
                {
                    rel: 'canonical',
                    href: 'https://illapa.cloud'
                }
            ]
        }
    },
}
</script>

<style scoped>

</style>