<template>
    <div class="faq" :id="id" v-bind:class="{ active: active }" @click="toggleComponent">
        <div class="faq-content">
            <div class="faq-heading">
                <!-- <i class="bi faq-heading-icon" :class="icon"></i> -->
                <h5 class="faq-heading-title">{{ title }}</h5>
                <i class="bi toggle-control" v-bind:class="{ active: active }"></i>
            </div>
            <p class="faq-information">{{ information }}</p>
            <p v-if="secondaryInformation != ''" class="faq-information">{{ secondaryInformation }}</p>
            <p v-if="terniaryInformation != ''" class="faq-information">{{ terniaryInformation }}</p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        id: String,
        title: String,
        information: String,
        secondaryInformation: String,
        terniaryInformation: String
    },
    data: () => ({
        active: false
    }),
    methods: {
        toggleComponent() {
            this.active = !this.active;
        },
        collapseComponent() {
            this.active = false;
        }
    }
}
</script>

<style scoped>
.faq {
    padding: 1.5rem;
    margin-bottom: 1rem;
    background: #FFF;
    border-radius: 6px;
    max-height: 85px;
    overflow: hidden;
    cursor: pointer;
    user-select: none;
    background: #f2f3f678;
    transition: box-shadow 0.35s ease,
                max-height 0.35s ease,
                background 0.35s ease;
}
.faq:not(.active):hover {
    background: white;
    box-shadow: 0 3px 28px 0 rgba(0,0,0,.08);
}
.faq.active {
    border: none;
    max-height: 650px;
    box-shadow: 0 3px 52px 0 rgba(0,0,0,.14);
    z-index: 1;
}
.faq.active .faq-content .faq-heading .faq-heading-title {
    -webkit-line-clamp: 2;
            line-clamp: 2;
}
.faq-content {
    text-align: left;
}
.faq-content .faq-heading {
    display: flex;
    justify-content: left;
    align-items: center;
}
.faq-content .faq-heading .toggle-control {
    margin-left: auto;
    color: #9EB7CD;
    font-size: 32px;
}
.faq-content .faq-heading .toggle-control::before {
    content: "\F4FE";
}
.faq-content .faq-heading .toggle-control.active::before {
    content: "\F2EA";
    color: var(--dark-default);
}
.faq-content .faq-heading .faq-heading-title {
    margin-bottom: 0;
    color: var(--text-dark-terniary);
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
            line-clamp: 1;
    -webkit-box-orient: vertical;
}
.faq-content .faq-information {
    line-height: 1.5;
    color: var(--text-dark-secondary);
    font-size: 18px;
    font-weight: 300;
    margin: 0;
    overflow: hidden;
    margin-top: 1.5rem;
    transition: max-height .35s ease;
}
</style>