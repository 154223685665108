<template>
    <div class="learn-more-button-wrapper">
        <div class="learn-more-button">            
            <slot/>
            <span class="button-icon">
                <i class="bi bi-arrow-right-short"></i>
            </span>
        </div>
    </div>
</template>

<script>
export default { }
</script>

<style scoped>
.learn-more-button-wrapper {
    display: inline-block;
}
.learn-more-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: left;
    flex-grow: 0;
    color: var(--brand-accent-blue);
    font-size: 16px;
    font-weight: var(--text-medium);
    cursor: pointer;
}
.learn-more-button a {
    text-decoration: none;
    color: var(--brand-accent-blue);
}
.learn-more-button:hover > .button-icon {
    transform: translateX(5px);
}
.learn-more-button .button-icon {
    font-size: 24px;
    transition: transform 0.2s ease;
}
</style>