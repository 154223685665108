<template>
    <div>
        <div class="take-flight-banner flex align-center">
            <div>
                <div class="take-flight-banner-heading text-light">
                    Take it for a spin!
                </div>
                <h5 class="text-light text-dark-primary">
                    Generate more revenue through an enhanced client experience?<br>
                    Increase operational efficiency through client self-service?<br>
                    Accelerate digital transformation through our API framework?<br>
                </h5>
                <h5 class="text-regular text-dark-primary">
                    What can the illapa Multi-Cloud Self-Service Platform do for you?
                </h5>
                <div class="cta-buttons">
                    <router-link :to="{ name: 'poc-trial' }">
                        <CTAButtonBlue>Test Drive</CTAButtonBlue>
                    </router-link>
                    <router-link :to="{ name: 'video-library' }">
                        <CTAButtonWhite>Watch Demo</CTAButtonWhite>
                    </router-link>
                </div>
            </div>
            <div class="take-flight-banner-image">
                <img
                    src="/images/take-flight-image.webp"
                    alt="take-flight-image.webp"
                >
            </div>
        </div>
    </div>
</template>

<script>
import CTAButtonBlue from "../buttons/CTAButtonBlue.vue";
import CTAButtonWhite from "../buttons/CTAButtonWhite.vue";

export default {
    components: {
        CTAButtonBlue,
        CTAButtonWhite
    }
}
</script>

<style scoped>
.take-flight-banner {
    max-width: 1300px;
    border-radius: 12px;
    position: relative;
    padding: 2.5rem;
    text-align: left;
    box-shadow: 0 15px 50px 0 rgba(0,188,197,0.2);
    overflow: hidden;
}
.take-flight-banner::before {
    content: "";
    width: 250px;
    height: 250px;
    background-image: url("../../assets/mission-image-dots.svg");
    position: absolute;
    top: -60px;
    left: -60px;
    mask-image: linear-gradient(120deg,#000,transparent);
}
.take-flight-banner::after {
    content: "";
    width: 150px;
    height: 150px;
    background-image: url("../../assets/mission-image-dots.svg");
    position: absolute;
    bottom: -60px;
    right: -60px;
    mask-image: linear-gradient(-90deg,#000,transparent);
}
.take-flight-banner .take-flight-banner-heading {
    font-size: 32px;
    margin-bottom: 30px;
    color: var(--text-dark-terniary);
}
.take-flight-banner .cta-buttons {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.take-flight-banner .cta-buttons button {
    margin-left: 0;
}
.take-flight-banner .take-flight-banner-image {
    position: absolute;
    right: -21px;
    bottom: -4px;
}
.take-flight-banner .take-flight-banner-image img {
    width: 100%;
    max-width: 560px;
    mask-image: linear-gradient(-45deg, black 0%, rgba(0,0,0,0) 60%);
}
@media only screen and (max-width: 1024px) {
    .take-flight-banner .take-flight-banner-image {
        display: none;
    }
}
</style>