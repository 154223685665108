<template>
    <div class="hero">
        <div class="container">
            <!-- Hero Content -->
            <div class="hero-content">
                <Transition name="fade-slide-up-slow" appear>
                    <h2 class="text-medium text-glow hero-heading">
                        Multi-Cloud Self-Service Platform
                    </h2>
                </Transition>
                <Transition name="fade-slide-up-slow" appear>
                    <h4 class="text-light hero-strapline" style="transition-delay: 0.2s">
                        Accelerate time to value through the automation, and continuous orchestration of all your IaaS business processes
                    </h4>
                </Transition>
                <Transition name="fade-slide-up-slow" appear>
                    <div class="hero-buttons" style="transition-delay: 0.4s">
                        <router-link :to="{ name: 'poc-trial' }">
                            <CTAButtonBlue>Test Drive</CTAButtonBlue>
                        </router-link>
                        <router-link :to="{ name: 'video-library' }">
                            <CTAButtonWhite>Watch Demo</CTAButtonWhite>
                        </router-link>
                    </div>
                </Transition>
            </div>
        </div>
        <div class="hero-dots-globe"></div>
        <div class="hero-glow"></div>
        <div class="hero-bottom-curve"></div>
    </div>
</template>

<script>
import CTAButtonBlue from "../buttons/CTAButtonBlue.vue";
import CTAButtonWhite from "../buttons/CTAButtonWhite.vue";

export default {
    components: {
        CTAButtonBlue,
        CTAButtonWhite
    },
    data: () => ({
        heroDotsGlobeLoaded: false
    }),
    methods: {
        scrollToContent() {
            document.getElementById('main-content').scrollIntoView();
        },
        heroDotsGlobeOnLoaded() {
            this.heroDotsGlobeLoaded = true;
        }
    }
}
</script>

<style scoped>
.hero {
    height: calc(100vh - var(--navbar-height));
    position: relative;
    background-color: var(--dark-default);
}
.hero-content {
    position: relative;
    text-align: center;
    color: #fff;
    max-width: 610px;
    margin: 0 auto;
    z-index: 2;
}
.hero-content .hero-heading {
    font-size: 40px;
    font-weight: 300;
}
.hero-content .hero-strapline {
    color: var(--text-dark-secondary);
    font-weight: 300;
    font-size: 24px;
}
.text-glow {
    text-shadow: 0px 2px 15px rgba(255,255,255,0.5), 1px 1px 10px var(--dark-default);
}
.hero-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
}
.hero-bottom-curve {
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    background-image: url("../../assets/hero-curve-white.svg");
    background-size: cover;
    background-position: bottom center;
    background-repeat: no-repeat;
    height: 110px;
    z-index: 1;
}
.hero-dots-globe {
    background-image: url("/images/hero-dots-globe.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    height: 100vh;
    bottom: -480px;
    left: 0;
    right: 0;
    opacity: 0.8;
    z-index: 0;
}
.hero-glow {
    position: absolute;
    bottom: -700px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    height: 1000px;
    box-shadow: 0 0 200px 215px var(--brand-primary-blue);
    background-color: var(--brand-primary-blue);
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    opacity: 0.1;
    z-index: 0;
}
/* Media Queries */
/* IMPORTANT: KEEP MEDIA QUERIES AT BOTTOM OF STYLING */
@media only screen and (max-width: 600px) {
    .hero-content {
        padding-top: 60px;
    }
}
@media only screen and (min-width: 600px) {
    .hero-content {
        padding-top: 120px;
    }
}
@media only screen and (min-width: 992px) {
    .hero-content {
        padding-top: 180px;
    }
}
</style>