<template>
    <section class="main-content gutter-bottom" id="main-content">
        <div class="container">
            <!-- Present your cloud services with style -->
            <section class="pillar-wrapper">
                <div class="pillar-heading"> <!-- v-scrollanimation -->
                    <h1 class="pillar-heading-title">
                        Build your cloud infrastructure services with style
                    </h1>
                    <h5 class="pillar-heading-strapline">
                        Delivering a hybrid multi-cloud service with illapa is a breeze, after all, who better to control the clouds than a weather god?
                    </h5>
                </div>
                <div class="pillar-item">
                    <div class="pillar-item-image"> <!-- v-scrollanimation -->
                        <img
                            src="/images/style-your-platform.webp"
                            alt="Style your platform"
                        >
                    </div>
                    <div class="pillar-item-information pillar-gutter-left"> <!-- v-scrollanimation -->
                        <h3 class="pillar-item-title text-regular">
                            Style your platform
                        </h3>
                        <h5 class="pillar-item-description">
                            With advanced styling features, you can truly make the platform your own. Adjust colours, brand images, and terms to make your customers feel at home.
                        </h5>
                        <router-link :to="{ name: 'video-library' }">
                            <LearnMoreButtonText>Find out more</LearnMoreButtonText>
                        </router-link>
                    </div>
                </div>
                <div class="pillar-item">
                    <div class="pillar-item-information pillar-gutter-left"> <!-- v-scrollanimation -->
                        <h3 class="pillar-item-title text-regular">
                            Create your marketplace
                        </h3>
                        <h5 class="pillar-item-description">
                            Fast start with our extensive library of instances. Create your own blueprints for a truly bespoke user experience.
                        </h5>
                        <router-link :to="{ name: 'video-library' }">
                            <LearnMoreButtonText>Find out more</LearnMoreButtonText>
                        </router-link>
                    </div>
                    <div class="pillar-item-image"> <!-- v-scrollanimation -->
                        <img
                            src="/images/create-your-marketplace.webp"
                            alt="Create your marketplace"
                        >
                    </div>
                </div>
                <div class="pillar-item">
                    <div class="pillar-item-image"> <!-- v-scrollanimation -->
                        <img
                            src="/images/provision-to-any-cloud.webp"
                            alt="Provision to any cloud"
                        >
                    </div>
                    <div class="pillar-item-information pillar-gutter-left"> <!-- v-scrollanimation -->
                        <h3 class="pillar-item-title text-regular">
                            Provision to any cloud
                        </h3>
                        <h5 class="pillar-item-description">
                            Our user friendly interface allows you to easily navigate and provision your instances. Oversee your deployments and manage them hassle-free.
                        </h5>
                        <router-link :to="{ name: 'video-library' }">
                            <LearnMoreButtonText>Find out more</LearnMoreButtonText>
                        </router-link>
                    </div>
                </div>
            </section>
            <!-- All the tools and features you need -->
            <section class="pillar-wrapper" style="z-index: 3;">
                <div class="pillar-heading"> <!-- v-scrollanimation -->
                    <div>
                        <h1 class="pillar-heading-title">All the tools and features you need</h1>
                        <h5 class="pillar-heading-strapline wide">
                            We create parity within a hybrid multi-cloud environment across three core pillars of business: Procurement, Operations, and Finance, accelerating time to value with your digital transformation.
                            <br><br>
                            Through our simplified user experience 80% of tasks can now be democratised to the line of business, or external customer, with 20% being delivered from IT specialists.
                            <br><br>
                            This hybrid approach to a service delivery model achieves the nirvana of a self-service model, but with comprehensive policy management.
                        </h5>
                        <div class="features-learn-more">
                            <router-link :to="{ name: 'features', query: { feature: 'reports' } }">
                                <CTAButtonBlue>Features</CTAButtonBlue>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="pillar-features-wrapper" v-on:change-image="image = 'marketplace-illustration-temp.jpg'"> <!-- v-scrollanimation -->
                    <div class="pillar-features">
                        <FeaturesOverview></FeaturesOverview>
                    </div>
                    <div class="pillar-feature-illustration">
                        <div class="illustration" id="illustration">
                            <!-- Reports -->
                            <img
                                v-show="reports"
                                src="/images/features/reports/reports.webp"
                                alt="Reports"
                                id="reports-illustration"
                            >
                            <div v-show="reports" class="data-popup" id="reports-data-popup">
                                <div class="data-popup-content">
                                    <div class="data-popup-heading">
                                        <i class="bi bi-file-earmark-text data-popup-heading-icon"></i>
                                        <h5 class="data-popup-heading-title">Reports</h5>
                                    </div>
                                    <p class="data-popup-information">The ability to accurately report on any situation is an imperative part of any team, and cloud is no exception, the more dynamic the situation the more critical that reporting is exact, and timely, as without this matters can quickly fall out of compliance, and this usually means exposure either financially or technically.</p>
                                </div>
                            </div>
                            <!-- Approvals -->
                            <img
                                v-show="approvals"
                                src="/images/features/approvals/approvals.webp"
                                alt="Approvals"
                                id="approvals-illustration"
                            >
                            <div v-show="approvals" class="data-popup" id="approvals-data-popup">
                                <div class="data-popup-content">
                                    <div class="data-popup-heading">
                                        <i class="bi bi-check-all data-popup-heading-icon"></i>
                                        <h5 class="data-popup-heading-title">Approvals</h5>
                                    </div>
                                    <p class="data-popup-information">The channel typically works on credit, whereas the cloud uses credit card or direct debit, this creates a conundrum of how do you create a self-service model using credit without risk of unauthorised expenditure. Illapa creates policy through user profiles and uses this to determine customisable approval processes where no resource can be provisioned without the appropriate authorisation.</p>
                                </div>
                            </div>
                            <!-- Instances -->
                            <img
                                v-show="instances"
                                src="/images/features/instances/instances.webp"
                                alt="Instances"
                                id="instances-illustration"
                            >
                            <div v-show="instances" class="data-popup" id="instances-data-popup">
                                <div class="data-popup-content">
                                    <div class="data-popup-heading">
                                        <i class="bi bi-display data-popup-heading-icon"></i>
                                        <h5 class="data-popup-heading-title">Instances</h5>
                                    </div>
                                    <p class="data-popup-information">Opposed to static long term contracts, cloud is dynamic by nature, and therefore the day to day management of provisioned resources needs to reflect this, illapa not only enables a change of state, but tracks all changes on the fly to provide a comprehensive audit trail, and immediately updates billing data with the amendments.</p>
                                </div>
                            </div>
                            <!-- Sub Tenants -->
                            <img
                                v-show="subTenants"
                                src="/images/features/sub-tenants/sub-tenants.webp"
                                alt="Sub Tenants"
                                id="sub-tenants-illustration"
                            >
                            <div v-show="subTenants" class="data-popup" id="sub-tenants-data-popup">
                                <div class="data-popup-content">
                                    <div class="data-popup-heading">
                                        <i class="bi bi-people data-popup-heading-icon"></i>
                                        <h5 class="data-popup-heading-title">Sub Tenants</h5>
                                    </div>
                                    <p class="data-popup-information">These can be temporary projects, or a perpetual line of business as technically each sub-tenant will be logically separated by billing, network and datastore. This can be used to set and manage user groups and budgets that could be used as a cost of sale against a revenue generating project, or fully automate show back/chargeback accounting to a line of business.</p>
                                </div>
                            </div>
                            <!-- Instance Library -->
                            <img
                                v-show="instanceLibrary"
                                src="/images/features/instance-library/instance-library.webp"
                                alt="Instance Library"
                                id="instance-library-illustration"
                            >
                            <div v-show="instanceLibrary" class="data-popup" id="instance-library-data-popup">
                                <div class="data-popup-content">
                                    <div class="data-popup-heading">
                                        <i class="bi bi-box data-popup-heading-icon"></i>
                                        <h5 class="data-popup-heading-title">Instance Library</h5>
                                    </div>
                                    <p class="data-popup-information">The key differentiator of our marketplace is that from this single library a user can provision to any cloud public or private anywhere in the world. We do not do license management that is the role of our partners, we build and maintain the instance types, cloud layouts, and virtual images including version control so you can experience a simple, repeatable process from a common UI regardless of your choice of infrastructure.</p>
                                </div>
                            </div>
                            <!-- Billing -->
                            <img
                                v-show="billing"
                                src="/images/features/billing/billing.webp"
                                alt="Billing"
                                id="billing-illustration"
                            >
                            <div v-show="billing" class="data-popup" id="billing-data-popup">
                                <div class="data-popup-content">
                                    <div class="data-popup-heading">
                                        <i class="bi bi-currency-dollar data-popup-heading-icon"></i>
                                        <h5 class="data-popup-heading-title">Billing</h5>
                                    </div>
                                    <p class="data-popup-information">The ability to reconcile an invoice against a purchase order appears to have been lost as we transition from a transfer of title to a subscription business model. As we have complete transparency across the three pillars of procurement, management and finance, along with dynamic updates it is really simple now for finance teams to compare billing data to procurement. Any discrepancies can be easily identified through audit trails and billing detail.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- FAQs -->
            <section class="pillar-wrapper" id="faq-wrapper"> <!-- v-scrollanimation -->
                <div class="pillar-heading">
                    <h1 class="pillar-heading-title">FAQs</h1>
                </div>
                <div class="pillar-faq-wrapper">
                    <div class="pillar-faqs">
                        <FAQOverview></FAQOverview>
                    </div>
                </div>
            </section>
            <!-- Take Flight Banner -->
            <section class="pillar-wrapper"> <!-- v-scrollanimation -->
                <div class="pillar-take-flight-wrapper">
                    <TakeFlight></TakeFlight>
                </div>
            </section>
        </div>
    </section>
</template>

<script>
import LearnMoreButtonText from "../buttons/LearnMoreButtonText.vue";
import FeaturesOverview from "../features/FeaturesOverview.vue";
import FAQOverview from "../faq/FAQOverview.vue";
import TakeFlight from "../take-flight/TakeFlight.vue";
import CTAButtonBlue from "../buttons/CTAButtonBlue.vue";

export default {
    components: {
        LearnMoreButtonText,
        FeaturesOverview,
        FAQOverview,
        TakeFlight,
        CTAButtonBlue
    },
    data: () => ({
        image: 'reports',
        reports: true, approvals: false, instances: false, subTenants: false, instanceLibrary: false, billing: false
    }),
    mounted: function () {
        this.$root.$on('change-image', (img) => {
            if (img != this.image) {                
                this.image = img;
                if (img == 'reports') {
                    this.setAllFeatureBooleansToFalse();
                    this.reports = true;
                }
                else if (img == 'approvals') {
                    this.setAllFeatureBooleansToFalse();
                    this.approvals = true;
                }
                else if (img == 'instances') {
                    this.setAllFeatureBooleansToFalse();
                    this.instances = true;
                }
                else if (img == 'sub-tenants') {
                    this.setAllFeatureBooleansToFalse();
                    this.subTenants = true;
                }
                else if (img == 'instance-library') {
                    this.setAllFeatureBooleansToFalse();
                    this.instanceLibrary = true;
                }
                else if (img == 'billing') {
                    this.setAllFeatureBooleansToFalse();
                    this.billing = true;
                }
                document.getElementById(img + '-illustration').animate([
                    { transform: 'translateY(43px)', opacity: 0, easing: 'ease' },
                    { transform: 'translateY(-43px)', opacity: 1, easing: 'ease' }
                ], {
                    duration: 1000
                });
                document.getElementById(img + '-data-popup').animate([
                    { transform: 'translateY(60px)', easing: 'ease' },
                    { transform: 'translateY(0px)', easing: 'ease' }
                ], {
                    duration: 1500
                });
                document.getElementById(img + '-data-popup').animate([
                    { opacity: 0, easing: 'ease' },
                    { opacity: 1, easing: 'ease' }
                ], {
                    duration: 600
                });
            }
        })
    },
    methods: {
        setAllFeatureBooleansToFalse() {
            this.reports = false;
            this.approvals = false;
            this.instances = false;
            this.subTenants = false;
            this.instanceLibrary = false;
            this.billing = false;
        }
    }
}
</script>

<style scoped>
.pillar-heading {
    margin-bottom: 80px;
}
.pillar-heading-strapline {
    max-width: 660px;
    margin: 0 auto;
}
.pillar-heading-strapline.wide {
    max-width: 1000px;
}
.pillar-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1215px;
    margin: 80px auto 0 auto;
}
.pillar-item-title {
    position: relative;
}
.pillar-item-title::before {
    content: "";
    width: 75px;
    height: 75px;
    position: absolute;
    left: -20px;
    bottom: 0;
    background-image: url("../../assets/pillar-heading-dots.svg");
    background-size: cover;
    background-position: bottom center;
    background-repeat: no-repeat;
}
.pillar-item-image > img {
    width: 100%;
    max-width: 700px;
}
.pillar-item-information {
    text-align: left;
    max-width: 650px;
}
.pillar-gutter-left {
    margin-left: 50px;
}
.pillar-features-wrapper {
    display: flex;
    align-items: flex-start;
}
.pillar-features {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    flex: 0 0 40%;
    width: 100%;
    max-width: 40%;
}
.pillar-feature-illustration {
    flex: 0 0 60%;
    max-width: 60%;
}
.pillar-feature-illustration .illustration {
    position: relative;
}
.pillar-feature-illustration .illustration img {
    width: 100%;
    transform: translateY(-43px);
    mask-image: linear-gradient(180deg,#000 40%, transparent 90%);
}
.pillar-wrapper .features-learn-more {
    padding: 3rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.pillar-wrapper .features-learn-more button {
    margin: 0;
}
.pillar-wrapper .pillar-faq-wrapper {
    max-width: 1000px;
    margin: 0 auto;
}
.pillar-wrapper .pillar-take-flight-wrapper {
    margin-top: 160px;
}
.data-popup {
    padding: 1.5rem;
    background: #FFF;
    border-radius: 10px;
    box-shadow: 0 10px 40px 0 rgba(0,0,0,.07);
    max-width: 500px;
    position: absolute;
    bottom: 10px;
    left: 0;
    z-index: 1;
    text-align: left;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}
.data-popup-content {
    text-align: left;
}
.data-popup-content .data-popup-heading {
    display: flex;
    justify-content: left;
    align-items: center;
}
.data-popup-content .data-popup-heading .data-popup-heading-icon {
    font-size: 36px;
    color: var(--brand-primary-blue);
}
.data-popup-content .data-popup-heading .data-popup-heading-title {
    margin-bottom: 0;
    margin-left: 1.25rem;
    color: var(--dark-default);
    font-weight: 500;
}
.data-popup-content .data-popup-information {
    line-height: 1.5;
    color: var(--text-dark-secondary);
    font-size: 18px;
    font-weight: 300;
    margin: 0;
    overflow: hidden;
    margin-top: 1.5rem;
    transition: max-height .35s ease;
}
#faq-wrapper {
    z-index: 2;
}
/* Media Queries */
/* IMPORTANT: KEEP MEDIA QUERIES AT BOTTOM OF STYLING */
@media only screen and (max-width: 600px) {
    .pillar-heading {
        padding-top: 80px;
    }
    .pillar-feature-illustration {
        display: none;
    }
    .pillar-item-information {
        text-align: center;
    }
    .pillar-item-title::before {
        content: none;
    }
}
@media only screen and (min-width: 600px) {
    .pillar-heading {
        padding-top: 160px;
    }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
    .pillar-item-information {
        margin-bottom: 2.5rem;
    }
    .pillar-item-image {
        order: 2;
    }
    .pillar-gutter-left {
        margin-left: 0;
    }
}
@media only screen and (min-width: 992px) {
    .pillar-item {
        flex-direction: row;
        margin: 0 auto;
    }
    .pillar-item-image {
        width: 60%;
    }
    .pillar-item-information {
        width: 40%;
    }
}
@media only screen and (max-width: 1100px) {
    .pillar-features-wrapper {
        flex-direction: column;
    }
    .pillar-features, .pillar-feature-illustration {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .pillar-feature-illustration {
        margin-top: 50px;
    }
}
</style>