<template>
    <div>
        <!-- Operations Category -->
        <div class="feature-category" :class="{ active: operationsActive }" ref="operationsCategory">
            <div class="feature-category-content">
                <div class="feature-category-heading" @click="toggleOperationsCategory">
                    <h4 class="feature-category-heading-title">Operations</h4>
                    <i class="bi toggle-control" :class="{ active: operationsActive }"></i>
                </div>
                <div @click="expandReportsFeature">
                    <Feature :icon="'bi-file-earmark-text'"
                             id="reports"
                             title="Reports"
                             information="The ability to accurately report on any situation is an imperative part of any team, and cloud is no exception, the more dynamic the situation the more critical that reporting is exact, and timely, as without this the situation can quickly fall out of compliance, and this usually means exposure either financial or technical."
                             secondaryInformation=""
                             ref="reports"
                             :toggled="true">
                    </Feature>
                </div>
                <div @click="expandApprovalsFeature">
                    <Feature :icon="'bi-check-all'"
                             id="approvals"
                             title="Approvals"
                             information="The channel typically works on credit, whereas the cloud uses credit card or direct debit, this creates a conundrum of how do you create a self-service model using credit without risk of unauthorised expenditure. Illapa creates policy through user profiles and uses this to determine customisable approval processes where no resource can be provisioned without the appropriate authorisation."
                             secondaryInformation=""
                             ref="approvals"
                             :toggled="false">
                    </Feature>
                </div>
            </div>
        </div>
        <!-- Manage Category -->
        <div class="feature-category" :class="{ active: manageActive }" ref="manageCategory">
            <div class="feature-category-content">
                <div class="feature-category-heading" @click="toggleManageCategory">
                    <h4 class="feature-category-heading-title">Manage</h4>
                    <i class="bi toggle-control" :class="{ active: manageActive }"></i>
                </div>
                <div @click="expandInstancesFeature">                    
                    <Feature :icon="'bi-display'"
                             id="instances"
                             title="Instances"
                             information="Opposed to static long term contracts, cloud is dynamic by nature, and therefore the day to day management of provisioned resources needs to reflect this, illapa not only enables a change of state, but tracks all changes on the fly to provide a comprehensive audit trail, and immediately updates billing data with the amendments. "
                             secondaryInformation=""
                             ref="instances"
                             :toggled="false">
                    </Feature>
                </div>
                <div @click="expandSubTenantsFeature">                    
                    <Feature :icon="'bi-people'"
                             id="sub-tenants"
                             title="Sub Tenants"
                             information="These can be temporary projects, or a perpetual line of business as technically each sub-tenant will be logically separated by billing, network and datastore. This can be used to set and manage user groups and budgets that could be used as a cost of sale against a revenue generating project, or fully automate show back/chargeback accounting to a line of business."
                             secondaryInformation=""
                             ref="subTenants"
                             :toggled="false">
                    </Feature>
                </div>
            </div>
        </div>
        <!-- Marketplace Category -->
        <div class="feature-category" :class="{ active: marketplaceActive }" ref="marketplaceCategory">
            <div class="feature-category-content">
                <div class="feature-category-heading" @click="toggleMarketplaceCategory">
                    <h4 class="feature-category-heading-title">Marketplace</h4>
                    <i class="bi toggle-control" :class="{ active: marketplaceActive }"></i>
                </div>
                <div @click="expandInstanceLibraryFeature">                    
                    <Feature :icon="'bi-box'"
                             id="instance-library"
                             title="Instance Library"
                             information="The key differentiator of our marketplace is that from this single library a user can provision to any cloud public or private anywhere in the world. We do not do license management that is the role of our partners, we build and maintain the instance types, cloud layouts, and virtual images including version control so you can experience a simple, repeatable process from a common UI regardless of your choice of infrastructure."
                             secondaryInformation=""
                             ref="instanceLibrary"
                             :toggled="false">
                    </Feature>
                </div>
            </div>
        </div>
        <!-- Finance Category -->
        <div class="feature-category" :class="{ active: financeActive }" ref="financeCategory">
            <div class="feature-category-content">
                <div class="feature-category-heading" @click="toggleFinanceCategory">
                    <h4 class="feature-category-heading-title">Finance</h4>
                    <i class="bi toggle-control" :class="{ active: financeActive }"></i>
                </div>
                <div @click="expandBillingFeature">                    
                    <Feature :icon="'bi-currency-dollar'"
                             id="billing"
                             title="Billing"
                             information="The ability to reconcile an invoice against a purchase order appears to have been lost as we transition from a transfer of title to a subscription business model. As we have complete transparency across the three pillars of procurement, management and finance, along with dynamic updates it is really simple now for finance teams to compare billing data to procurement. Any discrepancies can be easily identified through audit trails and billing detail."
                             secondaryInformation=""
                             ref="billing"
                             :toggled="false">
                    </Feature>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Feature from "./Feature.vue";

export default {
    components: {
    Feature
},
    data: () => ({
        operationsActive: true,
        manageActive: false,
        marketplaceActive: false,
        financeActive: false
    }),
    methods: {
        // Category Click Events
        toggleOperationsCategory() {
            this.operationsActive = !this.operationsActive;
            this.manageActive = false;
            this.marketplaceActive = false;
            this.financeActive = false;
        },
        toggleManageCategory() {
            this.manageActive = !this.manageActive;
            this.operationsActive = false;
            this.marketplaceActive = false;
            this.financeActive = false;
        },
        toggleMarketplaceCategory() {
            this.marketplaceActive = !this.marketplaceActive;
            this.operationsActive = false;
            this.manageActive = false;
            this.financeActive = false;
        },
        toggleFinanceCategory() {
            this.financeActive = !this.financeActive;
            this.operationsActive = false;
            this.manageActive = false;
            this.marketplaceActive = false;
        },
        // Individual Feature Click Events
        expandReportsFeature() {
            this.collapseAllFeatures();
            this.$refs.reports.enableComponent();
            this.$root.$emit('change-image', 'reports');
        },
        expandApprovalsFeature() {
            this.collapseAllFeatures();
            this.$refs.approvals.enableComponent();
            this.$root.$emit('change-image', 'approvals');
        },
        expandInstancesFeature() {
            this.collapseAllFeatures();
            this.$refs.instances.enableComponent();
            this.$root.$emit('change-image', 'instances');
        },
        expandSubTenantsFeature() {
            this.collapseAllFeatures();
            this.$refs.subTenants.enableComponent();
            this.$root.$emit('change-image', 'sub-tenants');
        },
        expandInstanceLibraryFeature() {
            this.collapseAllFeatures();
            this.$refs.instanceLibrary.enableComponent();
            this.$root.$emit('change-image', 'instance-library');
        },
        expandBillingFeature() {
            this.collapseAllFeatures();
            this.$refs.billing.enableComponent();
            this.$root.$emit('change-image', 'billing');
        },
        collapseAllFeatures() {
            this.$refs.reports.collapseComponent();
            this.$refs.approvals.collapseComponent();
            this.$refs.instances.collapseComponent();
            this.$refs.subTenants.collapseComponent();
            this.$refs.instanceLibrary.collapseComponent();
            this.$refs.billing.collapseComponent();
        }
    }
}
</script>

<style scoped>
.feature-category {
    margin-bottom: 1rem;
    border-radius: 4px;
    max-height: 90px;
    padding-left: 20px;
    padding-right: 20px;
    overflow: hidden;
    user-select: none;
    transition: box-shadow 0.35s ease,
                max-height 0.6s cubic-bezier(0, 1, 0, 1);
}
.feature-category.active {
    max-height: 999px;
    z-index: 1;
    transition: max-height 0.5s ease-in-out;
}
.feature-category-content {
    text-align: left;
}
.feature-category-content .feature-category-heading {
    position: relative;
    display: flex;
    justify-content: left;
    align-items: center;
    cursor: pointer;
    padding: 1.5rem;
    border-radius: 6px;
    background-color: #f2f3f6;
    margin-bottom: 1rem;
}
.feature-category-content .feature-category-heading .toggle-control {
    margin-left: auto;
    font-size: 32px;
}
.feature-category-content .feature-category-heading .toggle-control::before {
    content: "\F4FE";
    color: var(--text-dark-terniary);
}
.feature-category-content .feature-category-heading .toggle-control.active::before {
    content: "\F2EA";
}
.feature-category-content .feature-category-heading .feature-category-heading-title {
    margin-bottom: 0;
    color: var(--text-dark-terniary);
    font-weight: 500;
}
/* Media Queries */
@media only screen and (max-width: 600px) {
    .feature.active {
        /* // ! This will need to change depending on how much text is put inside the feature card */
        max-height: 500px;
    }
}
@media only screen and (max-width: 1100px) {
    .feature-category {
        padding-left: 0;
        padding-right: 0;
    }
    .feature {
        box-shadow: none;
        /* border: 2px solid #e7eef3 !important; */
    }
    .feature:hover {
        box-shadow: none;
        /* border-color: rgba(30,189,195,0.25); */
    }
    .feature.active {
        box-shadow: none;
        /* border: 2px solid var(--brand-primary-blue) !important; */
        background: rgba(29,189,195,.11) !important;
    }
}
</style>