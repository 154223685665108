<template>
    <div class="feature" :id="id" v-bind:class="{ active: active }" @click="enableComponent">
        <div class="feature-content">
            <div class="feature-heading">
                <i class="bi feature-heading-icon" :class="icon"></i>
                <h4 class="feature-heading-title">{{ title }}</h4>
                <i class="bi toggle-control" v-bind:class="{ active: active }"></i>
            </div>
            <p class="feature-information">{{ information }}</p>
            <p v-if="secondaryInformation != ''" class="feature-information">{{ secondaryInformation }}</p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        icon: {
            type: String,
            required: false,
            default: ''
        },
        id: String,
        title: String,
        information: String,
        secondaryInformation: String,
        toggled: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data: () => ({
        active: false
    }),
    methods: {
        toggleComponent() {
            this.active = !this.active;
        },
        enableComponent() {
            this.active = true;
        },
        disableComponent() {
            this.active = false
        },
        collapseComponent() {
            this.active = false;
        }
    },
    mounted () {
        this.active = this.toggled;
    }
}
</script>

<style scoped>
.feature {
    padding: 1.5rem;
    margin-bottom: 1rem;
    background: #FFF;
    border-radius: 4px;
    max-height: 91px;
    overflow: hidden;
    cursor: pointer;
    user-select: none;
    transition: box-shadow 0.35s ease,
                max-height 0.35s ease,
                background 0.35s ease;
}
.feature:hover {
    background: rgba(29, 189, 195, 0.11);
}
.feature.active {
    border: none;
    background: rgba(29, 189, 195, 0.11);
    z-index: 1;
}
.feature.active .feature-content .feature-heading .feature-heading-title {
    color: var(--brand-primary-blue);
}
.feature-content {
    text-align: left;
}
.feature-content .feature-heading {
    display: flex;
    justify-content: left;
    align-items: center;
}
.feature-content .feature-heading .feature-heading-icon {
    font-size: 36px;
    color: var(--brand-primary-blue);
}
.feature-content .feature-heading .toggle-control {
    margin-left: auto;
    color: #9EB7CD;
    font-size: 20px;
}
.feature-content .feature-heading .toggle-control.active {
    color: var(--brand-primary-blue);
}
.feature-content .feature-heading .toggle-control::before {
    content: "\F285";
}
.feature-content .feature-heading .toggle-control.active::before {
    content: "\F285";
}
.feature-content .feature-heading .feature-heading-title {
    margin-bottom: 0;
    margin-left: 1.25rem;
    color: var(--text-dark-terniary);
    font-weight: 400;
    transition: color 0.2s ease;
}
.feature-content .feature-information {
    line-height: 1.5;
    color: var(--text-dark-terniary);
    font-size: 18px;
    font-weight: 300;
    margin: 0;
    overflow: hidden;
    margin-top: 1.5rem;
    transition: max-height .35s ease;
}
</style>